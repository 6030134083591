import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { PAYMENT_TERM_OPTIONS } from '../../helpers/options';
// schemes
import * as VS from '../../schemes';
// settings
import { getBillToContactSettings } from '../../settings';
//////////////////////////////////////////////////

export const defaultLocationFields = {
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_ADDRESS_2]: '',
  [GC.FIELD_TEMPLATE_ID]: '',
  [GC.FIELD_PAYMENT_TERM]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  [GC.FIELD_LOCATION_TYPE]: '',
};

export const defaultContactFields = {
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_PHONE]: '',
  [GC.FIELD_CONTACT_NAME]: '',
};

export const defaultLatLngFields = {
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_LONGITUDE]: '',
};

const validationSchemaLocationWithContactObject = R.mergeRight(
  VS.validationSchemaContactObject,
  VS.validationSchemaLocationObject,
);

const validationSchemaLocationMainObject = R.mergeRight(
  validationSchemaLocationWithContactObject,
  {
    [GC.FIELD_LOCATION_TYPE]: G.yupStringRequired
      .max(40, G.getShouldBeFromToLocaleTxt(1, 40)),
    [GC.FIELD_TEMPLATE_ID]: G.yupStringRequired
      .max(100, G.getShouldBeFromToLocaleTxt(1, 100)),
  },
);

const locationAllFieldsValidationSchemaObject = R.mergeRight(
  VS.validationSchemaLatLngObject,
  validationSchemaLocationMainObject,
);

export const getLocationValidationSchemaObject = (saveAsTemplate: boolean) => {
  if (G.isTrue(saveAsTemplate)) {
    return locationAllFieldsValidationSchemaObject;
  }

  return validationSchemaLocationWithContactObject;
};

const branchContactsValidationSchema = R.assoc(
  GC.FIELD_CONTACT_NAME,
  G.yupStringRequired,
  VS.validationSchemaContactObject,
);

export const branchLocationValidationSchemaObject = R.compose(
  R.omit([GC.FIELD_PHONE, GC.FIELD_EMAIL, GC.FIELD_CONTACT_NAME, GC.FIELD_PHONE_EXTENSION]),
  R.assoc(GC.FIELD_CONTACTS, Yup.array().of(Yup.object().shape(branchContactsValidationSchema))),
)(locationAllFieldsValidationSchemaObject);

export const contractBillToLocationValidationSchemaObject = R.mergeRight(
  VS.validationSchemaPayTermObject,
  validationSchemaLocationMainObject,
);

const inputWrapperStyles = {
  mb: 25,
  width: 200,
};

// BILL TO
const billToLocationFields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:template-id', 'Template ID'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: GC.FIELD_LOCATION_TYPE,
    fieldName: GC.FIELD_LOCATION_TYPE,
    label: ['titles:location-type', 'Location Type'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address1', 'Address1'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 11 },
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ADDRESS_2,
    label: ['titles:address2', 'Address2'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_CITY,
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_ZIP,
    label: ['titles:postal-code', 'Postal Code'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
  },
];

const billToContactFieldset = {
  fields: getBillToContactSettings(200),
};

export const billToLocationSectionSettings = [
  {
    fields: billToLocationFields,
  },
  billToContactFieldset,
];
// BILL TO

export const contractBillToLocationSettings = [
  {
    fields: R.prepend(
      {
        type: 'select',
        isRequired: true,
        inputWrapperStyles,
        fieldName: GC.FIELD_PAYMENT_TERM,
        label: ['titles:payment-terms', 'Payment Terms'],
        options: R.prepend(GC.EMPTY_OPTION_OBJECT, PAYMENT_TERM_OPTIONS),
      },
      R.dropLast(2, billToLocationFields),
    ),
  },
  billToContactFieldset,
];

// BRANCH LOCATION
export const branchLocationSectionFieldSettings = R.append(
  {
    type: 'textarea',
    fieldName: GC.FIELD_COMMENTS,
    inputWrapperStyles: { width: '100%' },
    label: ['titles:comments', 'Comments'],
  },
  billToLocationFields,
);

const contactInputWrapperStyles = {
  mr: 20,
  width: 200,
};

export const branchContactSectionFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CONTACT_NAME,
    inputWrapperStyles: contactInputWrapperStyles,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: contactInputWrapperStyles,
  },
  {
    type: 'number',
    inputWrapperStyles: { width: 150 },
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
    inputWrapperStyles: R.assoc('mt', 25, contactInputWrapperStyles),
  },
  {
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax-number', 'Fax Number'],
    inputWrapperStyles: R.assoc('mt', 25, contactInputWrapperStyles),
  },
];
